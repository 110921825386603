<template>
  <div class="share">
    <div class="wrapper">
      <div class="title font-md">
        <span>SNS 공유</span>
      </div>
      <div class="desc font-sm">
        <span>{{ urls.display }}</span>
        <span class="pointer badge badge-point" @click="copyUrl(urls.value)">복사</span>
      </div>
      <div class="icons">
        <div class="row">
          <div class="col-6">
            <div class="icon facebook" @click="share('facebook')">
              <span class="img" style="background-image: url(/assets/ico/common.sns.facebook.svg)"></span>
              <div>페이스북</div>
            </div>
          </div>
          <div class="col-6">
            <div class="icon twitter" @click="share('twitter')">
              <span class="img" style="background-image: url(/assets/ico/common.sns.twitter.svg)"></span>
              <div>트위터</div>
            </div>
          </div>
          <div class="col-6">
            <div class="icon kakao" @click="share('kakaolink')">
              <span class="img" style="background-image: url(/assets/ico/common.sns.kakao.svg)"></span>
              <div>카카오톡</div>
            </div>
          </div>
          <div class="col-6">
            <div class="icon band" @click="share('band')">
              <span class="img" style="background-image: url(/assets/ico/common.sns.band.line.svg)"></span>
              <div>밴드</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import lib from "@/scripts/lib";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "modalShare";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("initSnsShare");
      store.commit("tuneModal", {component, size: "xs"});
    });

    const state = reactive({
      result: []
    });

    const modalParams = store.getters.modalParams(component);
    const urls = {
      display: [window.location.host, modalParams.path.replace("www.", "")].join(""),
      value: [window.location.origin, modalParams.path].join("")
    };

    const copyUrl = (url) => {
      lib.copyToClipboard(url);
      store.dispatch("callback", {component});
      store.commit("setSwingMessage", "클립보드에 복사하였습니다.");
    };

    const share = (platform) => {
      if (lib.isPreviewPage()) {
        return store.commit("setSwingMessage", definitions.messages.noSupportInPreview);
      }

      const url = urls.value;
      const title = modalParams.title;
      const desc = modalParams.desc || "";
      const imgUrl = modalParams.img.url || "";
      const imgWidth = modalParams.img.width || "";
      const imgHeight = modalParams.img.height || "";

      store.dispatch("callback", {component});
      store.dispatch("share", {platform, title, desc, url, imgUrl, imgWidth, imgHeight});
    };

    return {component, state, urls, copyUrl, share};
  }
});
</script>

<style lang="scss" scoped>
.share {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
      word-break: break-all;

      span {
        vertical-align: middle;

        &.badge {
          margin-left: $px6;
          margin-top: $px1;
        }
      }
    }

    .icons {
      text-align: center;
      max-width: $px250;
      margin: 0 auto;

      .icon {
        cursor: pointer;
        display: inline-block;
        border-radius: 50%;
        padding: $px15;

        > .img {
          background-color: #eee;
          width: $px70;
          height: $px70;
          margin-bottom: $px8;
          background-position: 50%;
          border-radius: 50%;
        }

        &.facebook {
          > .img {
            background-color: #445d98;
            background-size: $px55;
          }
        }

        &:hover {
          font-weight: 500;

          > .img {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
</style>